import React, { useState} from "react";
import { graphql } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';
import withRoleRequired from '../lib/withRoleRequired';
import Layout from "../components/layout";
import SEO from "../components/seo";

import { Evidence, EvidenceQueryData } from '../types/Global';
import { mapEdgesToNodes } from '../lib/helpers';

interface Props {
  data: {
    allEvidenceCsv: EvidenceQueryData[]
  }
}

const EvidenceCatalogue: React.FunctionComponent<Props> = ({ data }) => {
  const evidences: Evidence[] = mapEdgesToNodes(data.allEvidenceCsv)
  const { getAccessTokenSilently } = useAuth0();
  const [ token, setToken ] = useState('');
  getAccessTokenSilently().then((t) => {
    setToken(t);
  }) ;
  return (
    <Layout moreInfo='evidence-catalogue'>
      <SEO
        keywords={[`aleph`, `dstl`, `future`, `workforce`, `evidence`, `catalogue`]}
        title="Evidence Catalogue"
      />
      <div className="max-w-7xl px-4 py-4 mx-auto md:px-8 md:py-4">
        <section>
          This section provides a list of research reports and other documentation from across the research project that provided the information presented within this tool. Short descriptions are provided of the different documents. Click on the pdf icon in order to download a copy of the document.<br/>

          <b>Please note that each of the appendices was extracted from O-HSSRC-1.012-010 (HS 1.012 Understanding the Future Workforce (2030+) Final Report) which was produced under DEFCON 705.</b>
        </section>
        <div className="mt-8 grid grid-cols-12 gap-1">
  
          <div className="col-span-1 px-3 py-3 bg-dstl-dark-gray text-sm sm:text-lg font-bold flex justify-center items-center">&nbsp;</div>
          <div className="col-span-4 px-3 py-3 bg-dstl-dark-gray text-sm sm:text-lg font-bold flex justify-center items-center">Document Name</div>
          <div className="col-span-4 px-3 py-3 bg-dstl-dark-gray text-sm sm:text-lg font-bold flex justify-center items-center">High Level Description</div>
          <div className="col-span-3 px-3 py-3 bg-dstl-dark-gray text-sm sm:text-lg font-bold flex justify-center items-center">Link to Download</div>
  
          { evidences && evidences.map((e, i) => (
            <>
              <div className="col-span-1 h-full bg-dstl-light-gray text-sm sm:text-lg font-bold flex justify-center items-center">{i + 1}</div>
              <div className="col-span-4 h-full bg-dstl-light-gray text-sm sm:text-lg font-bold flex items-center">
                <div className="flex flex-col sm:flex-row">
                  <div className="flex-1 flex justify-start items-center px-3 py-3 text-left">
                    {e.name}
                  </div>
                </div>
              </div>
              <div className="h-full col-span-4 px-3 py-3 bg-dstl-light-gray text-sm sm:text-lg flex text-left items-center">{e.description}</div>
              <a className="col-span-3" href={`/pdfs${e.link}?access_token=${token}`} rel="noreferrer">
                <div className="h-full bg-dstl-light-gray text-sm sm:text-lg font-bold flex justify-center items-center">
                  <img className="max-h-24 w-24 py-3" alt="Section" src={e.type} />
                </div>
              </a>
            </>
          ))}
  
        </div>
      </div>
    </Layout>
  )
} 

export const pageQuery = graphql`
  query {
    allEvidenceCsv {
      edges {
        node {
          id
          name
          description
          type
          link
        }
      }
    }
  }
`


export default withRoleRequired(EvidenceCatalogue);
